<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Managers')"
      :header-action="showAddManager"
      :header-action-text="$t('Add')"
      icon="mdi-account-tie"
      >

    <template #default>
      <v-sheet class="wsRoundedHalf  mt-6">

        <v-data-table :items="managers"
                      :headers="headers"
                      class="wsHoverLight"
                      hide-default-header
        >

          <template v-slot:header="{ props }">
            <wsTableHeader :props="props" />
          </template>

        <template v-slot:item="{ item }">
          <tr @click="$router.push(businessDashLink('settings/manager/' + item.uuid))"
              class="pointer"
          >
            <td>
              <router-link class="noUnderline black--text" :to="businessDashLink('settings/manager/' + item.uuid)">
                <div class="d-flex align-center text-no-wrap">
                  <v-avatar class="mr-3"  :color="wsACCENT"
                            size="40" key="profile_picture" >
                    <v-img v-if="item.img" :src="item.img" transition="xxx" >
                      <template slot="placeholder">
                        <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
                      </template>
                    </v-img>
                    <v-icon  dark v-else>mdi-account-tie</v-icon>
                  </v-avatar>
                  <div>
                    <h5> {{ item.firstname }} {{ item.lastname }}  </h5>
                    <h6 class="font-weight-light"> {{ item.email}}</h6>
                  </div>
                </div>
              </router-link>

              </td>
              <td width="10px" class="text-no-wrap">
                <wsChip icon="mdi-cogs" :color="wsACCENT" :text="$t('Permissions') + ': ' + item.permissions.length "/>
              </td>
              <td v-if="IS_BUSINESS_OWNER"
                  width="10px">
                <v-btn @click.stop="openDeleteManager(item)" icon :color="wsACCENT">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

      </v-sheet>
    </template>

    <template #dialog>

      <ws-dialog
          v-if="displayDelete"
          v-model="displayDelete"
          :title="$t('DeleteManager')"
          @delete="deleteManager"
          display-confirm-delete
          close-delete
          :confirm-delete-code="selectedManagerUuid"
      />

      <ws-dialog v-model="displayDialog" width="600"
                 :title="$t('AddManager')"
                 @save="addManager">

        <div class="px-1 pb-1">
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.firstname"
                  :placeholder="$t('Firstname')"
                  :label="$t('Firstname')"
                  :error="nameError"
                  @input="nameError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.lastname"
                  :placeholder="$t('Lastname')"
                  :label="$t('Lastname')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.password"
                  :placeholder="$t('Password')"
                  :label="$t('Password')"
                  :error="passwordError"
                  @input="passwordError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.repeat_password"
                  :placeholder="$t('PasswordRepeat')"
                  :label="$t('PasswordRepeat')"
                  :error="passwordError"
                  @input="passwordError = false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.email"
                  :placeholder="$t('Email')"
                  :label="$t('Email')"
                  :error="emailError || userError"
                  @input="emailError = false ; userError = false"
              />
            </v-col>
            <v-col cols="6">
              <ws-phone-input
                  :label="$t('Phone')"
                  :error="phoneError || userError"
                  @input="phoneError=false ; userError = false "
                  v-model="entityData.phone"
                  hide-details
              />
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="6">
              <wsDatePicker :label="$t('Birthday')" :placeholder="$t('ChooseDate')" v-model="entityData.birthday" />
            </v-col>
            <v-col cols="6">
              <ws-text-field
                  v-model="entityData.country"
                  :placeholder="$t('Country')"
                  :label="$t('Country')"
              />
            </v-col>
          </v-row>
        </div>



      </ws-dialog>
    </template>


  </sub-page>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BusinessDasboardManagers",
  data(){
    return {
      entityData : {
        isAdmin : false,
      },
      displayDelete : false,
      selectedManagerUuid : null,
      managers : [],
      users : [],
      displayDialog : false,
      showEditPermissions : false,
      selectedManager : {},
      selectedManagerIndex : null,
      modulesPermissions : [],

      editActions : [

        { label: 'Редагувати', action: 'edit'   },
        { label: 'Видалити',   action: 'delete' },

      ],

      passwordError : false,
      emailError : false,
      phoneError : false,
      nameError : false,
      userError : false,

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),

    headers() {
      return [
        { text : this.$t('HumanName') , value : 'name' },
        { text : this.$t('Permissions') , value : 'permissions' },
        { value : 'action' }
      ]
    },

    generalPermissionTypes() {
      return [
        { text : this.$t('Administrator'), value : true },
        { text : this.$t('LimitedRights'), value : false }
      ]
    }
  },
  methods : {
    ...mapActions( 'businessUsers', [
      'ADD_MANAGER' ,
      'DELETE_MANAGER' ,
      'GET_MANAGERS'
    ]),

    openDeleteManager(item) {
      this.selectedManagerUuid = item.uuid
      this.displayDelete = true
    },
    async deleteManager() {
      let result = await this.DELETE_MANAGER(this.selectedManagerUuid)
      if ( !result ) {
        return this.ERROR()
      }
      let index = this.managers.findIndex(el => el.uuid === this.selectedManagerUuid)
      if ( index === -1 ) {
        return
      }
      this.managers.splice(index ,  1)
      this.notify(this.$t('ManagerDeleted'))
      this.displayDelete = false
    },
    async addManager() {

      if ( !this.entityData.email) {
        this.emailError = true
        return this.notify(this.$t('EnterEmail'))
      }
      if(  !this.reg.test(this.entityData.email)  ) {
        this.emailError = true
        this.notify(this.$t('WrongEmail'));
        return
      }
      if ( !this.entityData.password) {
        this.passwordError = true
        return this.notify(this.$t('EnterPassword'))
      }
      if ( this.entityData.password !== this.entityData.repeat_password ) {
        this.passwordError = true
        return this.notify(this.$t('PasswordNotMatch'))
      }
      if ( !this.entityData.phone) {
        this.phoneError = true
        return this.notify(this.$t('EnterPhone'))
      }
      if ( !this.entityData.firstname) {
        this.nameError = true
        return this.notify(this.$t('EnterFirstname'))
      }


      let result = await this.ADD_MANAGER(this.entityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'User already exist' ) {
          this.userError = true
          this.userError = true
          this.notify(this.$t('UserAlreadyExists'))
        } else {
          this.notify(this.$t('Error'))
        }

        return
      }
      this.notify(this.$t('ManagerCreated') )

      this.managers.unshift(result)
      this.displayDialog = false
    },

    showAddManager() {
      this.displayDialog = true
    },
    updateManagers(data){
      this.managers.unshift(data)
      this.displayDialog = false
    },

    editButton(action,item,index) {

      switch(action) {
        case 'edit'         : this.openManagerPemissions(item,index)    ; break;
        case 'delete'       : this.deleteManager(item)            ; break;
      }

    },
    openManagerPemissions(item,index) {
      this.selectedManager = item
      this.selectedManagerIndex = index
      this.showEditPermissions = true
    },

    // techical
    getPermissionName(permission){
      switch(permission) {
        case 'MODULE_NEWS'       : return 'Новини';
        case 'MODULE_PRODUCTS'   : return 'Товари';
        case 'MODULE_ORDERS'     : return 'Замовлення';
        case 'MODULE_WAREHOUSE'  : return 'Склад';
        case 'MODULE_DELIVERY'   : return 'Доставка';
        case 'MODULE_TEMPLATER'  : return 'Дизайн';
        case 'MODULE_STATISTICS' : return 'Статистика';

        default : return permission
      }
    },
    getPermissionIcon(permission){
      switch(permission) {
        case 'MODULE_NEWS'       : return 'mdi-newspaper-variant-outline';
        case 'MODULE_PRODUCTS'   : return 'mdi-cart-variant';
        case 'MODULE_ORDERS'     : return 'mdi-text-box-check-outline';
        case 'MODULE_WAREHOUSE'  : return 'mdi-warehouse';
        case 'MODULE_DELIVERY'   : return 'mdi-warehouse';
        case 'MODULE_TEMPLATER'  : return 'mdi-account';
        case 'MODULE_STATISTICS' : return 'mdi-chart-bar';

        default : return permission
      }
    }
  },
  async mounted() {

    this.loading = true
    let result = await this.GET_MANAGERS()
    if ( !result || result === true ) {
      return
    }

    this.managers = result
    this.managers.reverse()


  }

}
</script>

<style scoped>

</style>